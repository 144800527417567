.skills-component {
    @apply overflow-hidden h-[240px];
    /* Screen Size > 810px */
    @apply md:max-w-[49%];
}

.row ul {
    /* All Screen Sizes */
    @apply flex flex-row place-items-center relative m-0 p-0 gap-0;
    @apply w-full h-full max-w-full max-h-full;
}

.skill-container {
    /* All Screen Sizes */
    @apply h-auto relative place-content-center items-center flex flex-row gap-[12px];
    @apply h-min overflow-hidden pr-[12px] w-min;
}

.skill-text {
    /* All Screen Sizes */
    @apply place-content-center items-center flex gap-[10px] h-[36px] overflow-visible;
    @apply relative p-[12px] w-min bg-element-background rounded-[12px] text-text-color;
    @apply flex-row justify-start shrink-0 whitespace-pre;
}

.moving-text-separator {
    /* All Screen Sizes */
    @apply flex flex-col gap-[24px];
}

.moving-text-container {
    /* All Screen Sizes */
    @apply relative left-[-50px];
}

.row {
    @apply w-full inline-flex flex-nowrap overflow-hidden;
}

.row ul {
    /* All Screen Sizes */
    @apply flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll;
    @apply w-auto;
}

.row ul li {
    @apply m-0;
}

.firstRow ul {
    animation-duration: 15s;
}

.secondRow ul {
    animation-duration: 35s;
}

.thirdRow ul {
    animation-duration: 20s;
}