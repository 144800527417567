button.cta {
    /* All Screen Sizes */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    gap: 1.5rem;
    height: 42px;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    color: var(--color-text);
    background-color: var(--color-element-background);
    border-radius: 12px;
}