.aboutus-images-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.border {
    height: 17rem;
    width: 10rem;
    background-color: transparent;
    border-radius: 0.5rem;
    transition: all 1s;
    position: relative;
    border: 1px solid white;

    @media (min-width: 640px) {
        height: 19rem;
        width: 14rem;
    }

    @media (min-width: 768px) {
        height: 21rem;
        width: 16rem;
    }

    @media (min-width: 1024px) {
        height: 23rem;
        width: 18rem;
        border-color: transparent;

        &:hover {
            border-color: white;
        }
    }
}

.image-card {
    height: 18rem;
    width: 11rem;
    border-radius: 0.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1s;
    overflow: hidden;
    background-color: black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        background-position: left center;
        background-repeat: no-repeat;
        animation: zoomBackground 1s forwards;
        filter: none;
    }

    @media (min-width: 640px) {
        height: 20rem;
        width: 15rem;
    }

    @media (min-width: 768px) {
        height: 22rem;
        width: 17rem;
    }

    @media (min-width: 1024px) {
        height: 24rem;
        width: 19rem;
        filter: grayscale(100%);
    }

    &:nth-child(3) {
        @media (min-width: 768px) {
            right: -50%;
        }

        @media (min-width: 1024px) {
            right: 0;
        }
    }

    h2,
    h3 {
        font-size: 0.8rem;
        position: absolute;
        transition: opacity 1s;
        font-weight: 800;
        color: #FDEDDD;
        text-shadow: 3px 3px 6px black;
        margin: 20px;

        @media (min-width: 1024px) {
            font-size: 1rem;
            opacity: 0;
        }
    }

    &:hover h2,
    &:hover h3 {
        opacity: 1;
    }
}

@keyframes zoomBackground {
    from {
        background-size: 120%;
    }

    to {
        background-size: 200%;
    }
}

@keyframes zoomOutBackground {
    from {
        background-size: 200%;
    }

    to {
        background-size: 120%;
    }
}