.contact-component {
    /* All Screen Sizes */
    @apply flex-none h-auto w-full relative;
}

.contact-grid {
    /* All Screen Sizes */
    @apply grid grid-cols-1 gap-[24px] justify-center items-center w-full;
    @apply md:grid-cols-2;
    /* 2 columns from small screens and up */
}

.contact-container {
    /* All Screen Sizes */
    @apply flex flex-row relative gap-[12px] bg-element-background rounded-[8px];
    @apply p-2 items-center justify-center;
}

.contact-form-container {
    @apply flex flex-col gap-[12px] p-4 w-full;
}

.row {
    @apply flex flex-row gap-[12px] w-full;
}

.form-group {
    @apply flex-1;
}

input,
textarea {
    @apply w-full p-2 rounded-md outline-none text-text-color;
}

#hubspotForm {
    width: 100%;
}