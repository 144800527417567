.arrow-aboutus {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    z-index: -10;
    top: 100px;
    opacity: 0;

    @media (min-width: 1024px) {
        transform: translateX(-50%) translateY(-40%);
        rotate: 0;
    }
}

.arrow-aboutus svg {
    color: var(--icons-color);
    font-size: 4em;
}

.arrow-aboutus.hover {
    z-index: 10;
}

.aboutus-images-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding-left: 40px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    img {
        object-fit: cover;
        height: 48px;
        height: 96px;
    }
}

.image-container {
    position: relative;
    display: inline-block;

    img {
        overflow: hidden;
        border-radius: 12px;
        transition: transform 300ms ease-in-out;
        max-width: 256px;
        max-height: 256px;
        display: block;
        position: relative;
        z-index: 1;
    }

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 300ms ease-in-out;
        border-radius: 12px;
        z-index: 2;
        pointer-events: none;
    }

    &:hover::before {
        opacity: 1;
    }
}

.centre-p-aboutus {
    display: flex;
    align-items: center;
    justify-content: center;
}

.education-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-right: 20px;
}

.education-item {
    background: #0f0f0f;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 66.6667%;
}

.education-name {
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.education-details {
    margin-top: 8px;
}

.education-degree {
    font-size: 1rem;
    color: #9ca3af;
}

.education-year {
    font-size: 0.875rem;
    color: #6b7280;
}