.audio-visualiser-container {
    @apply absolute;
}

.particles {
    /* Full height and width of the page */
    @apply h-full w-full fixed top-0 left-0 -z-10;
}

.waveform-canvas {
    @apply z-10;
}