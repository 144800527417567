@tailwind base;
@tailwind components;
@tailwind utilities;

.example-style {
    /* All Screen Sizes */
    /* Screen Size < 810px */
    /* Screen Size < 1200px */
    /* Screen Size > 1200px */
}

body {
    /* All Screen Sizes */
    background-color: var(--background-color);
    font-family: sans-serif;
    font-size: 16px;
}

.hidden {
    display: none;
}

.column-to-row {
    /* All Screen Sizes */
    @apply max-w-[1000px] w-full contents place-content-center;
    @apply items-center gap-[24px] relative;
    @apply md:flex md:flex-row;
}

.title-container {
    /* All Screen Sizes */
    @apply flex-none h-auto relative w-full;
}

.title-container .title-col-divider {
    /* All Screen Sizes */
    @apply flex flex-row flex-nowrap items-center justify-start relative;
    @apply gap-[8px] h-min p-0 overflow-hidden;
}

.component {
    /* All Screen Sizes */
    @apply flex flex-none flex-nowrap flex-col items-start justify-center;
    @apply gap-[24px] rounded-[30px] p-[24px];
    @apply h-min w-full max-w-[1000px] bg-component-background shadow-lg;
}

p {
    /* All Screen Sizes */
    color: #999999;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply text-text-color;
}

h2.title {
    /* All Screen Sizes */
    @apply text-xl font-normal;
}

.contentContainer {
    /* All Screen Sizes */
    @apply flex flex-col flex-nowrap items-center justify-start min-h-screen w-auto pr-6 pl-6 pt-6 pb-24 gap-[24px];
}

@media only screen and (min-width: 1000px) {
    .column-to-row section {
        height: 240px !important;
    }
}

/*End Animations*/
/*
-- Start BackGround Animation 
*/
.area {
    background: -webkit-linear-gradient(to left, var(--black), var(--grey));
    width: 100%;
    height: fit-content;
    position: relative;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

/*
-- End BackGround Animation 
*/

/* Color Changing code */
/* Add this to your global styles or header.css */
html,
body {
    transition: background-color 1s ease-out;
    /* Smooth transition for background color */
}

.page-transition {
    animation: colorTransition 1s ease-out;
}

@keyframes colorTransition {
    from {
        background-color: var(--old-background-color);
    }

    to {
        background-color: var(--new-background-color);
    }
}