.stacks-component .stacks-container {
    @apply flex flex-col gap-6;
}

.stack-container {
    @apply flex flex-col gap-2 text-white;
    @apply transition-transform duration-300 ease-in-out;
    @apply hover:scale-[1.02];
}

.stack-container .title-container {
    @apply flex items-center gap-4;
}

.stack-container .title-icon-section {
    @apply flex-shrink-0 max-w-8 max-h-8;
}

.stack-container .title-section {
    @apply flex flex-col;
}

.stack-container .title-section .title {
    @apply text-lg font-semibold;
}

.stack-container .title-section .subtitle {
    @apply text-sm text-gray-400;
}

.stack-container .description-container {
    @apply flex gap-4;
}

.stack-container .vertical-divider {
    @apply w-px bg-gray-600;
}

.stack-container .description {
    @apply text-base;
}

.button-container {
    @apply flex justify-center w-full;
}