.interactable-globe-container {
    @apply flex-col flex-none flex-nowrap justify-start items-start relative;
    @apply h-[240px] overflow-hidden;
    /* Screen Size > 810px */
    @apply md:max-w-[49%];
}

.globe-container {
    @apply flex justify-center items-center h-full w-full relative;
    @apply aspect-square left-0 right-0 top-[10vw];
    /* Screen Size < 810px */
    /* Screen Size > 810px */
    @apply md:top-[5vw];
    /* Screen Size > 1880px */
    @apply 2xlg:top-[3vw];
}