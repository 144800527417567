
:root {
  --background-color: #000000;
  --color-icons: #FDEDDD;
  --color-component-background: #0f0f0f;
  --color-element-background: #1a1a1a;
  --color-element-transparent: rgba(26, 26, 26, 0.8);
  --particles-color: #FFFFFF;
  --color-text: #FDEDDD;
}

body {
  margin: 0;
}

#chatbot-toggler {
  position: fixed;
  bottom: 30px;
  right: 35px;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  background: #FFFFFF;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 100;
  margin-bottom: 70px;
}

.container.show-chatbot #chatbot-toggler {
  transform: rotate(90deg);
}

#chatbot-toggler span:last-child,
.container.show-chatbot #chatbot-toggler span:first-child{
  opacity: 0;
}

.container.show-chatbot #chatbot-toggler span:last-child{
  opacity: 1;
}

#chatbot-toggler span {
  position: absolute;
  color: #1a1a1a;
}

.chatbot-popup {
  opacity: 0;
  pointer-events: none;
  border-radius: 20px;
  bottom: 90px;
  right: 35px;
  width: 21rem;
  overflow: hidden;
  position: fixed;
  /* box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5); */
  transform: scale(0.2);
  transform-origin: bottom right;
  transition: all 0.1s ease;
}

.container.show-chatbot .chatbot-popup {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
  z-index: 1000;
}

.chatbot-popup .chat-header {
  display: flex;
  background: var(--color-element-transparent); 
  /* opacity: 90%; */
  align-items: center;
  justify-content: space-between;
  padding: 15px 22px;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
}

.chat-header .header-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.header-info svg {
  height: 35px;
  width: 35px;
  padding: 6px;
  background: #fff;
  border-radius: 50%;
  fill:black;
  flex-shrink: 0;
}

.header-info .logo-text {
  color: #fff;
  font-size: 1.31rem;
  font-weight: 600;
}

.chat-header button {
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.9rem;
  padding-top: 2px;
  margin-right: -10px;
  border-radius: 50%;
  transition: 0.2s ease;
  background: none;
}

.chat-header button:hover {
  background: #593bab;
}

.chat-body {
  padding: 25px 22px;
  height: 42vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;
  scrollbar-width: thin;
  background: #fff;
}

.chat-body .message {
  display: flex;
  align-items: center;
  gap: 11px;

}

.chat-body .bot-message svg {
  height: 35px;
  width: 35px;
  padding: 6px;
  fill: #fff;
  border-radius: 50%;
  /* background: #rgba(255, 255, 255, 0.2); */
  flex-shrink: 0;
  margin-bottom: 2px;
  align-self: flex-end;
}

.chat-body .message .message-text {
  max-width: 75%;
  padding: 12px 16px;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.95rem;
  color: #000000;
}

.chat-body .bot-message .message-text {
  background: #f6f2ff;
  border-radius: 13px 13px 13px 3px;
  padding: 10px;
}

.chat-body .user-message {
  flex-direction: column;
  align-items: flex-end;
}

.chat-body .user-message .message-text {
  background: #f6f2ff;
  border-radius: 13px 13px 3px 13px;
  /* color: #fff; */
  padding: 10px;
}

.chat-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 15px 22px 20px;
}

.chat-footer .chat-form {
  display: flex;
  align-items: center;
  background: #fff;
  outline: 1px solid #cccce5;
  border-radius: 32px;
}

.chat-form .message-input {
  border: none;
  outline: none;
  width: 100%;
  background: none;
  height: 37px;
  padding: 0 17px;
  font-size: 0.95rem;
  color: rgb(79, 74, 74);
}

.chat-form button {
  height: 35px;
  width: 35px;
  /* background: rgba(255, 255, 255, 0.2); */
  color: #fff;
  flex-shrink: 0;
  display: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 50%;
  border: none;
  outline: none;
  margin-right: 2px;
}

.changeButtonColor {
  background: #000000;
}

.chat-footer .chat-form:focus-within {
  outline: rgba(255, 255, 255, 0.2) 2px solid;
}

.chat-form .message-input:valid~button {
  display: block;
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v222/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDJ_vb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rIelXxc.woff2) format('woff2');
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  color:#000000;
}