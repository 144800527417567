.footer {
    @apply flex flex-col items-center text-white text-[3rem];
    @apply sm:text-[4.45rem] md:text-[10rem];
    @apply relative cursor-pointer overflow-hidden bg-component-background;
    @apply hover:bg-none !important;
    /* Added */
}



.footer-arrow {
    @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full;
    @apply lg:group-hover:translate-y-[-20%] rotate-90 lg:group-hover:rotate-0 transition-all duration-300;
}