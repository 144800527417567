.projects-component .button-container {
    /* All Screen Sizes */
    @apply flex-none h-auto w-full relative;
}

.project-grid {
    /* All Screen Sizes */
    @apply grid grid-cols-1 gap-[24px];
    @apply md:grid-cols-2 mx-auto;
    /* 2 columns from small screens and up */
}

.project-container {
    /* All Screen Sizes */
    @apply flex flex-col relative gap-[12px];
}

.image-container-projects {
    @apply overflow-hidden rounded-[12px];
    @apply transition-transform duration-300 ease-in-out;
}

.image-container-projects:hover {
    @apply transform -translate-y-6;
}

.image-container-projects img {
    @apply object-center object-cover;
    @apply w-[14rem] h-[14rem];
    @apply sm:w-[16rem] sm:h-[16rem];
    @apply md:w-[21rem] md:h-[21rem];
    @apply lg:w-[30rem] lg:h-[30rem];
    @apply transition-transform duration-300 ease-in-out;
    @apply hover:scale-110;
    /* Scale up on hover */
}

.tag {
    @apply rounded-[6px] mr-2 p-1 bg-element-background text-xs;
    @apply w-full text-text-color;
}

.title-container {
    @apply text-white;
}