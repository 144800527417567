.reviews-component {
    @apply relative w-full max-w-[800px] overflow-hidden bg-black;
    @apply flex items-center justify-center mx-auto;
}

.reviews-container {
    @apply flex whitespace-nowrap transition-transform duration-500 ease-in-out;
}

.review-card {
    @apply flex flex-col bg-component-background rounded-[30px] text-white;
    @apply flex-none w-[400px] mx-2 px-8 relative;
    box-sizing: border-box;
    /* Ensure padding and border are included in width */
}

.reviewer-info {
    @apply flex items-center mb-3;
    /* Adjusted margin for better spacing */
}

.reviewer-image {
    @apply w-12 h-12 rounded-[12px] mr-3;
    /* Smaller image size */
}

.reviewer-details {
    @apply flex flex-col;
}

.reviewer-name {
    @apply font-semibold;
    /* Adjust font size */
}

.reviewer-role {
    @apply text-sm text-gray-400;
}

.review-text {
    @apply text-sm whitespace-normal;
    /* Adjust text size and enable normal whitespace wrapping */
}

.nav-button {
    @apply absolute top-1/2 transform -translate-y-1/2 p-4 rounded-full bg-gray-800 text-white;
    @apply hover:bg-gray-700 transition-colors duration-300;
    @apply w-12 h-12;
    /* Increase button size */
    @apply flex items-center justify-center;
}

.prev-button {
    @apply left-44;
    /* Adjust button position */
}

.next-button {
    @apply right-44;
    /* Adjust button position */
}

@media (min-width: 768px) {
    .reviews-container {
        @apply flex-nowrap;
    }

    .review-card {
        @apply flex-none;
        /* Ensure the card's width remains consistent */
    }
}