.header {
    /* All Screen Sizes */
    @apply flex flex-col items-center max-w-[1000px] mx-auto bg-component-background text-white rounded-[2rem];
    @apply overflow-hidden shadow-lg pl-1.5 pr-1.5 pt-1.5 pb-6 relative;
    /* Screen Size < 810px */
    @apply md:justify-between md:flex-row md:pl-1.5 md:pt-1.5 md:pb-1.5 md:pr-5 md:rounded-3xl;
}

.logo-heading-container {
    /* All Screen Sizes */
    @apply flex-none h-auto relative;
    /* Screen Size < 1200px */
    @apply md:flex md:items-center md:flex-row md:flex-nowrap md:gap-[8px] md:relative md:justify-center;
}

.logo-container {
    /* All Screen Sizes */
    @apply rounded-[2rem] h-auto w-auto;
    /* Screen Size < 810px */
    @apply md:flex md:items-center md:rounded-3xl;
}

.logo-image {
    /* All Screen Sizes */
    @apply h-auto min-h-[260px] w-auto min-w-[260px] object-cover;
    /* Screen Size < 810px */
    @apply md:h-[68px] md:w-[68px] md:min-h-0 md:min-w-0;
}

.heading-container {
    /* All Screen Sizes */
    @apply text-center mt-6 flex flex-col items-center relative;
    /* Screen Size < 810px */
    @apply md:flex md:flex-col md:items-start md:justify-center md:mt-0;
    /* Screen Size < 1200px */
    @apply md:flex-none md:flex-nowrap md:flex-col md:gap-[2px] md:p-0 md:h-min md:w-min
}

.status {
    /* All Screen Sizes */
    @apply flex justify-center;
    /* Screen Size < 1200px */
    @apply items-center
}

.status-light {
    /* All Screen Sizes */
    @apply flex items-center w-max pl-2 mt-4 border-2 border-green-600 rounded-full;
    /* Screen Size < 810px */
    @apply mt-0;
}

.social-icons-container {
    /* All Screen Sizes */
    @apply flex justify-center mt-6 space-x-4;
    /* Screen Size < 810px */
    @apply md:mt-0 md:space-x-2 md:mr-4;
}

.social-icon-container {
    @apply bg-element-background rounded-xl h-[48px] w-[48px] flex items-center justify-center;
}

.colorChanger {
    @apply mt-4 md:mt-0 flex justify-center items-center bg-element-background rounded-xl h-[48px] w-[48px];
}

.colorChanger svg {
    @apply flex justify-center items-center p-0;
}

.hidden {
    display: none;
}