nav {
    @apply gap-4 bg-opacity-60 w-fit mx-auto z-[100];
    @apply gap-[12px] rounded-[20px] p-[10px];
    @apply flex gap-4 fixed bottom-[10px];
    @apply max-w-[300px] max-h-[76px];

    @apply md:max-w-[800px] md:p-[12px] md:rounded-[30px];

    backdrop-filter: blur(9px);
}

.navbar-container {
    @apply inline-flex justify-center items-center;
}

.icon-container {
    @apply relative bg-element-background rounded-[16px] p-2 flex items-center justify-center transition duration-300 w-[54px] h-[54px];
}

.icon-container:hover {
    @apply bg-opacity-20;
}

.icon-link {
    @apply text-white;
}

.icon-image {
    @apply max-w-[42px] max-h-[42px];
    @apply md:w-6 md:h-6;
}

.tooltip {
    @apply hidden absolute left-1/2 transform -translate-x-1/2 top-[-40px] bg-element-background text-text-color p-1.5 rounded-lg text-sm shadow-md whitespace-nowrap opacity-0 transition duration-300 ease-in-out;
}

.icon-container:hover .tooltip {
    @apply block opacity-100 animate-rise-and-expand;
}

@keyframes rise-and-expand {
    0% {
        transform: translate(-50%, 10px) scale(0.35);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, 0px) scale(1);
        opacity: 1;
    }
}

.animate-rise-and-expand {
    animation: rise-and-expand 0.3s ease-in-out forwards;
}